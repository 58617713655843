import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import Link from "next/link";
import { useRouter } from "next/router";

import React, { useState } from "react";
import { eventNames } from "services/mixpanel/events";
import trackEvent from "services/mixpanel/mixpanel";
import { useAuth } from "stores/auth/auth-context";
import {
  getPageNameFromPath,
  isKelasLivePage,
  isMarketingPage
} from "utils/utils";

import { Close } from "components/icons/close";
import { Hamburger } from "components/icons/hamburger";
import { Logo } from "../logo/logo";
import {
  DownloadButton,
  HamburgerIcon,
  HeaderCTA,
  HeaderContainer,
  HeaderMenuCTA,
  Menu,
  MenuContainer,
  MenuDivider,
  MenuItem,
  MenuLink,
  MobileMenuContainer,
  StyledHeader,
  MobileUserProfileContainer
} from "./header.styles";
import { IHeaderProps } from "./header.types";
import { MobileUserProfile, UserProfile } from "./user-profile/user-profile";

const Header: React.FC<IHeaderProps> = ({ hidesMenu = false }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const router = useRouter();

  const { user, signOutUser } = useAuth();
  const { userData, setUserData, isUserLoggedIn } = user || {};

  const isMobile = useMediaQuery("mobile");

  const handleLoginEvent = () => {
    trackEvent({
      eventName: eventNames.loginButtonClicked,
      payload: {
        incomingSource: "website",
        eventSource: "website",
        loginFromPage: getPageNameFromPath(router.pathname),
        isOnboarded: null
      }
    });
    router.push({
      pathname: "/login",
      query: router.query
    });
  };

  const handleHamburgerIconClick = () => {
    setMenuVisible(!isMenuVisible);
  };

  const kelasLivePage = isKelasLivePage(router.pathname);

  const hideMenuItems = isMarketingPage(router.pathname);

  return (
    <StyledHeader>
      <HeaderContainer>
        {!hidesMenu && !hideMenuItems && (
          <HamburgerIcon
            onClick={handleHamburgerIconClick}
            data-testid="header-burger-button"
          >
            {isMenuVisible ? <Close /> : <Hamburger />}
          </HamburgerIcon>
        )}
        <Logo />
        {(kelasLivePage || isMobile) && (
          <Link
            href="https://colearn.app.link/DownloadCoLearn"
            passHref
            legacyBehavior
          >
            <DownloadButton>Download</DownloadButton>
          </Link>
        )}

        {!hideMenuItems && (
          <MobileMenuContainer $active={isMenuVisible}>
            <div>
              {isUserLoggedIn && (
                <MobileUserProfileContainer>
                  <MobileUserProfile
                    userData={userData}
                    signOutUser={signOutUser}
                    setUserData={setUserData}
                  />
                </MobileUserProfileContainer>
              )}
              {isUserLoggedIn ? <MenuDivider /> : null}
            </div>

            <Menu>
              <MenuItem>
                <MenuLink
                  data-testid="bimbel-header-button"
                  href="/bimbel"
                  title="Bimbel"
                >
                  Bimbel
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink
                  data-testid="tanya-header-button"
                  href="/tanya"
                  title="Tanya"
                >
                  Tanya
                </MenuLink>
              </MenuItem>
            </Menu>
            <MenuDivider />
            <Menu>
              <MenuItem>
                <MenuLink
                  href="/blog/mengajar/gurujuara"
                  title="Ngajar di CoLearn"
                  data-testid="ngajar-header-button"
                >
                  Ngajar di CoLearn
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink
                  href="/paket"
                  title="Paket Belajar"
                  data-testid="paket-header-button"
                >
                  Paket Belajar
                </MenuLink>
              </MenuItem>
            </Menu>
            {isUserLoggedIn ? (
              <HeaderMenuCTA onClick={() => signOutUser()}>
                Keluar
              </HeaderMenuCTA>
            ) : (
              <Link
                href="https://colearn.app.link/DownloadCoLearn"
                passHref
                legacyBehavior
              >
                <DownloadButton $isFullWidth>Download</DownloadButton>
              </Link>
            )}
          </MobileMenuContainer>
        )}

        {!hidesMenu && !hideMenuItems && (
          <>
            <MenuContainer>
              <Menu>
                <MenuItem>
                  <MenuLink
                    data-testid="bimbel-header-button"
                    href="/bimbel"
                    title="Bimbel"
                  >
                    Bimbel
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    data-testid="tanya-header-button"
                    title="Tanya"
                    href="/tanya"
                  >
                    Tanya
                  </MenuLink>
                </MenuItem>
              </Menu>
              <Menu>
                <MenuItem>
                  <MenuLink
                    href="/blog/mengajar/gurujuara"
                    title="Ngajar di CoLearn"
                    data-testid="ngajar-header-button"
                  >
                    Ngajar di CoLearn
                  </MenuLink>
                </MenuItem>
                <MenuItem>
                  <MenuLink
                    href="/paket"
                    title="Paket Belajar"
                    data-testid="paket-header-button"
                  >
                    Paket Belajar
                  </MenuLink>
                </MenuItem>
              </Menu>
            </MenuContainer>

            {isUserLoggedIn ? (
              <UserProfile
                userData={userData}
                signOutUser={signOutUser}
                setUserData={setUserData}
              />
            ) : (
              !isMenuVisible &&
              !isMobile && (
                <HeaderCTA $placeRight onClick={handleLoginEvent}>
                  Masuk
                </HeaderCTA>
              )
            )}
          </>
        )}
      </HeaderContainer>
    </StyledHeader>
  );
};

export { Header };
