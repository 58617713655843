export const sizes = {
  containerWidth: "1180px",
  faqSectionWidth: "780px",
  headerHeight: "65px",
  logosSectionWidth: "730px",
  videoPlayerWidth: "780px",
  reviewSectionHeight: { mobile: "620px", tablet: "800px" },
  checkoutCardWidth: { tablet: "700px" },
  loginCardDisclaimerMaxWidth: "550px",
  formTitleWidth: "84px",
  kredivoPaymentCardWidth: { tablet: "800px" },
  userProfileWidth: { laptop: "250px" },
  userProfileDropdownMenuWidth: "300px",
  paketHeroSlideHeight: { mobile: "120px", laptop: "360px" },
  paymentMethodSectionWidth: { tablet: "1100px" },
  paketPageTestimonialWidth: "560px",
  loginCardInfoWidth: { laptop: "370px", tablet: "330px" },
  manageSubscriptionPageWidth: { laptop: "768px" },
  liveSectionVideoContainer: {
    mobile: { height: "170px", width: "300px" },
    laptop: { height: "200px", width: "350px" }
  },
  peerLearningImageContainer: {
    mobile: { height: "170px", width: "300px" },
    laptop: { height: "200px", width: "360px" }
  },
  homePageTestimonialWidth: "560px",
  testimonialCard: {
    mobile: { width: "260px", height: "360px" },
    laptop: { height: "286px" }
  },
  paketPageSlotCard: {
    laptop: {
      width: "270px"
    }
  },
  paketDropdownMenu: {
    mobile: { width: "100%", height: "67%" },
    laptop: { maxHeight: "600px", height: "unset", width: "320px" }
  },
  paketDropdownButton: {
    mobile: { width: "100%" },
    tablet: { width: "275px" }
  },
  loginCardCheckboxWidth: "327px",
  LoginBgWidth: "460px",
  checkShareStatusCTAWidth: "280px",
  sharePaymentOnWhatsappWidth: { mobile: "100%", laptop: "320px" },
  verificationEmailTitle: "400px",
  videoPlayerTimeStamp: { mobile: "32px", tablet: "45px" },
  kelasLive: {
    onGoingClassCardWidth: "1200px",
    upcomingClassCardWidth: "525px",
    heroSectionUpcomingClassCardWidth: "1200px",
    pastClassCardWidth: "500px",
    pastClassDetailModalVideo: "360px",
    pastClassDetailModalStudyMaterialCard: "410px",
    rateClassCardWidth: "525px"
  },
  packageSelectorCardWidth: { mobile: "150px", laptop: "240px" },
  dropdownListWidth: "220px",
  tanyaPaginationItem: {
    tablet: { width: "34px", height: "34px" },
    laptop: { width: "44px", height: "44px" }
  },
  pushToAppMinWidth: "345px",
  tenurePickerPageOfferWidth: {
    mobile: "330px",
    laptop: "630px"
  },
  paketPageHorizontalRuleWidth: {
    laptop: "1100px"
  },
  packageSelectorBonusTextContainer: {
    mobile: "120px",
    laptop: "180px"
  },
  paketPageOfferContainerWidth: "330px",
  PackageCardHeight: {
    mobile: "220px",
    laptop: "300px"
  },
  kelasLiveCallMeBackCTAWidth: "64px",
  kelasLiveCallMeBackCTAMessageWidth: "250px",
  scrollWeeklyScheduleHeight: "165px",
  scrollWeeklyScheduleWidth: "20%",
  tanya: {
    breadcrumbWidth: "820px"
  },
  mengajar: {
    articleWidth: "780px"
  }
};
