import { generateUUID } from "utils/utils";
import wretch from "wretch";
import {
  IUpdateStudentPayload,
  IUpdateStudentSuccessResponse,
  ICreateParentPayload,
  ICreateParentSuccessResponse,
  IUpdateParentPayload,
  IUpdateParentSuccessResponse,
  ISwitchPrimaryUserResponse,
  IVerifyStudentEmailResponse,
  IUpdateUserProfileResponse,
  IUpdateUserProfilePayload
} from "./user-services.types";

const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_API}`;

export const updateStudentDetails = (
  editFormData: IUpdateStudentPayload,
  token: string,
  user_id: string
): Promise<IUpdateStudentSuccessResponse> => {
  return wretch(`${BASE_URL}/user-management/v3/students/${user_id}/profile`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${token}`)
    .put(editFormData)
    .json();
};

export const createParentDetails = (
  createParentPayload: ICreateParentPayload,
  access_token: string,
  student_id: string
): Promise<ICreateParentSuccessResponse> => {
  return wretch(`${BASE_URL}/user-management/v3/students/${student_id}/parent`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${access_token}`)
    .post(createParentPayload)
    .json();
};

export const updateParentDetails = (
  updateParentPayload: IUpdateParentPayload,
  access_token: string,
  student_id: string,
  parent_id: string
): Promise<IUpdateParentSuccessResponse> => {
  return wretch(
    `${BASE_URL}/user-management/v3/students/${student_id}/parent/${parent_id}`
  )
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${access_token}`)
    .put(updateParentPayload)
    .json();
};
export const switchPrimaryUser = (
  switchTouserId: string,
  access_token: string
): Promise<ISwitchPrimaryUserResponse> => {
  return wretch(`${BASE_URL}/user-management/v1/users/switcher`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${access_token}`)
    .post({ switchTouserId })
    .json(
      ({ data: { access_token, refresh_token, expires_in, token_type } }) => ({
        access_token,
        refresh_token,
        expires_in,
        token_type
      })
    );
};

export const verifyUserEmail = (
  token: string
): Promise<IVerifyStudentEmailResponse> => {
  return wretch(`${BASE_URL}/user-management/v1/users/verify-email/${token}`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .put()
    .json();
};

export const updateUserProfile = (
  payload: IUpdateUserProfilePayload,
  access_token: string
): Promise<IUpdateUserProfileResponse> => {
  return wretch(`${BASE_URL}/user-management/v2/students/profile`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .auth(`Bearer ${access_token}`)
    .put(payload)
    .json();
};
