import { useState } from "react";
import { IApiError } from "services/api/api.types";
import {
  SubscriptionStatus,
  TanyaSubscriptionStatus
} from "services/api/sessions/sessionService.types";
import { getSubscriptionStatus } from "services/api/sessions/sessionsService";
import { useAuth } from "stores/auth/auth-context";
import { IUseSubscriptionStatus } from "./subscription-status.types";
import * as Sentry from "@sentry/react";
import { parseAPIErrorResponse } from "utils/utils";
import { useCookies } from "react-cookie";
import { constants } from "constants/constants";
import { TanyaSubscriptionInfo } from "services/api/sessions/sessionService.types";
import { registerSuperProperties } from "services/mixpanel/mixpanel";

const useSubscriptionStatus = (): IUseSubscriptionStatus => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isFetching, setIsFetching] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus | null>(null);
  const [tanyaSubscriptions, setTanyaSubscriptions] = useState<
    TanyaSubscriptionInfo[]
  >([]);

  const [isTanyaSubscriptionActive, setIsTanyaSubscriptionActive] =
    useState<boolean>(
      cookies[constants.userContext.cookieTanyaSubscriptionStatus] ===
        TanyaSubscriptionStatus.ACTIVE
    );

  const { signOutUser } = useAuth();

  const getActiveTanyaSubscription = (
    tanyaSubscriptionInfo: TanyaSubscriptionInfo[]
  ) => {
    return tanyaSubscriptionInfo.find(
      ({ status }) => status === TanyaSubscriptionStatus.ACTIVE
    );
  };

  const fetchSubscriptionStatus = async (token: string) => {
    try {
      setIsFetching(true);
      const {
        data: {
          subscriptionStatus,
          tanya_subscription: { subscription_info: tanyaSubscriptionInfo }
        }
      } = await getSubscriptionStatus(token);

      // Remove existing subscription related cookies
      removeCookie(constants.userContext.cookiePrimaryUserSubscriptionType, {
        path: "/"
      });
      removeCookie(constants.userContext.cookieTanyaSubscriptionStatus, {
        path: "/"
      });

      // Set user subscription
      setSubscriptionStatus(subscriptionStatus);

      setCookie(
        constants.userContext.cookiePrimaryUserSubscriptionType,
        subscriptionStatus.subscriptionType,
        { path: "/" }
      );
      let activeTanyaSubscriptionInfo: TanyaSubscriptionInfo | null = null;

      if (tanyaSubscriptionInfo) {
        setTanyaSubscriptions(tanyaSubscriptionInfo);

        activeTanyaSubscriptionInfo =
          getActiveTanyaSubscription(tanyaSubscriptionInfo) || null;

        if (activeTanyaSubscriptionInfo) {
          setIsTanyaSubscriptionActive(true);

          setCookie(
            constants.userContext.cookieTanyaSubscriptionStatus,
            activeTanyaSubscriptionInfo.status,
            { path: "/" }
          );
        }
        // Register tanya_pro super property
        registerSuperProperties({
          tanya_pro: activeTanyaSubscriptionInfo
            ? TanyaSubscriptionStatus.ACTIVE
            : TanyaSubscriptionStatus.EXPIRED
        });
      } else {
        registerSuperProperties({
          tanya_pro: TanyaSubscriptionStatus.EXPIRED
        });
      }
      setIsFetching(false);
      return { subscriptionStatus, activeTanyaSubscriptionInfo };
    } catch (err) {
      removeCookie(constants.userContext.cookiePrimaryUserSubscriptionType, {
        path: "/"
      });
      removeCookie(constants.userContext.cookieTanyaSubscriptionStatus, {
        path: "/"
      });
      const error = parseAPIErrorResponse(err);
      const {
        errors: { status }
      } = error as IApiError;
      if (status === 401) {
        signOutUser();
      }
      Sentry.captureException(err);
    }
    setIsFetching(false);
    return null;
  };

  return {
    subscriptionStatus,
    setSubscriptionStatus,
    fetchSubscriptionStatus,
    isTanyaSubscriptionActive,
    isFetching,
    getActiveTanyaSubscription,
    tanyaSubscriptions
  };
};

export { useSubscriptionStatus };
