import styled from "styled-components";
import { breakpoints } from "styles";

export const DownloadAppImageWrapper = styled.a`
  display: block;
  position: relative;
  width: 140px;
  height: 40px;
  transition: 0.3s ease-in-out;
  @media ${breakpoints.laptop} {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const DownloadAppContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  @media ${breakpoints.laptop} {
    justify-content: flex-start;
  }
`;
