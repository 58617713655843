import * as Sentry from "@sentry/react";
import { ContactMeLeadSource } from "components/contact-me/use-contact-me.types";
import { LoaderPageName } from "components/loader/loader.types";
import { OptimizelyExperiments } from "components/optimizely/optimizely.types";
import { IClassOptions, classOptions, constants } from "constants/constants";
import { format, intervalToDuration } from "date-fns";
import { ILoadingPageName } from "hooks/pages/use-loading/use-loading.types";
import queryString from "query-string";
import { IApiError, IPrimaryUser } from "services/api/api.types";
import { getLocalStorage } from "services/local-storage";
import { AuthContextType } from "stores/auth/auth-context.types";
import { v4 as uuidV4 } from "uuid";
import wretch from "wretch";
import { LoginClassType } from "./../types/common/common.types";
import { IUserSpendTimeData } from "./pages/utils.types";

export const genID = (charSize = 6): string => {
  const value = Date.now.toString();
  return value.substring(value.length, charSize);
};

export const trimString = (term: string, length: number): string =>
  term.length > length ? term.substring(0, length - 3) + "..." : term;

export const getQueryValue = (
  query: string | string[] | undefined
): string | null => (query && Array.isArray(query) ? query[0] : query || null);

export const getGradeGroup = (grade: string): string => {
  const gradeGroupMap: { [key: string]: string } = {
    4: "SD",
    5: "SD",
    6: "SD",
    7: "SMP",
    8: "SMP",
    9: "SMP",
    10: "SMA",
    11: "SMA",
    12: "SMA"
  };
  return gradeGroupMap[grade];
};

export const getSubjectInBahasa = (subject: string): string => {
  const subjectMap: { [key: string]: string } = {
    maths: "Matematika",
    physics: "Fisika",
    chemistry: "Kimia"
  };
  return subjectMap[subject.toLowerCase()];
};

export const getPageNameFromPath = (pathName: string): string => {
  const pagePathNameMap: Record<string, string> = {
    "/tanya/[questionId]/[slug]": "Tanya Video Page",
    "/tanya/topic/[grade]/[subject]/[topic]": "Tanya Video Listing Page",
    "/tanya/chapter/[grade]/[subject]/[chapter]": "Tanya Chapter Page",
    "/tanya/questions/page/[page]": "Tanya Recommended Video Page",
    "/tanya": "Tanya Home Page",
    "/bimbel": "Live Classes Home Page",
    "/bimbel/user": "Live Classes User Home Page",
    "/": "Home Page",
    "/bimbel-online": "Bimbel Sales Page",
    "/bimbel/paket/utbk-snbt": "Bimbel Package UTBK-SNBT Page"
  };

  return pagePathNameMap[pathName] || "";
};

export const getLeadSourceFromPath = (
  pathName: string
): ContactMeLeadSource | null => {
  const pagePathNameMap: Record<string, ContactMeLeadSource> = {
    "/tanya": ContactMeLeadSource.tanyaPageBanner,
    "/bimbel": ContactMeLeadSource.bimbelPageBanner,
    "/paket": ContactMeLeadSource.paketPageBanner,
    "/": ContactMeLeadSource.homePageBanner
  };
  return pagePathNameMap[pathName] || null;
};

export const isKelasLivePage = (pathName: string): boolean => {
  const pageName = getPageNameFromPath(pathName);
  const kelasLivePages: string[] = [
    "Live Classes Home Page",
    "Live Classes User Home Page"
  ];
  return kelasLivePages.includes(pageName);
};

export const isMarketingPage = (pathName: string): boolean => {
  const pageName = getPageNameFromPath(pathName);
  const marketingPages: string[] = ["Bimbel Sales Page"];
  return marketingPages.includes(pageName);
};

export const shouldHideMainFooterSection = (pathName: string): boolean => {
  const pageName = getPageNameFromPath(pathName);
  const pages: string[] = ["Bimbel Package UTBK-SNBT Page"];
  return pages.includes(pageName);
};

export const isTanyaPage = (pathName: string): boolean => {
  const pageName = getPageNameFromPath(pathName);
  const tanyaPages: string[] = [
    "Tanya Search Result Page",
    "Tanya Video Page",
    "Tanya Video Listing Page",
    "Tanya Chapter Page",
    "Tanya Home Page",
    "Tanya Recommended Video Page"
  ];
  return tanyaPages.includes(pageName);
};

export const getVideoPlayerDuration = (seconds: number): string => {
  try {
    if (seconds && !isNaN(seconds)) {
      const flooredSeconds = Math.floor(seconds);
      return new Date(flooredSeconds * 1000).toISOString().substr(14, 5);
    }
  } catch {
    return "00:00";
  }
  return "00:00";
};

export const lockDeviceOrientation = (mode: OrientationLockType): unknown =>
  (screen.orientation?.lock &&
    screen.orientation.lock(mode).then(
      () => null,
      (err) => console.log(err)
    )) ||
  // @ts-ignore
  (screen.mozLockOrientation && screen.mozLockOrientation(mode)) ||
  // @ts-ignore
  (screen.msLockOrientation && screen.msLockOrientation(mode));

export const unlockDeviceOrientation = (): void =>
  screen.orientation?.unlock
    ? screen.orientation.unlock()
    : // @ts-ignore
      screen.mozUnlockOrientation
      ? // @ts-ignore
        screen.mozUnlockOrientation()
      : // @ts-ignore
        screen.msUnlockOrientation
        ? // @ts-ignore
          screen.msUnlockOrientation()
        : null;

export const getSecondsDuration = (
  percentage: number,
  duration: string
): number => {
  return (Number(percentage) / 100) * toSeconds(duration);
};

export const toSeconds = (strTime: string): number => {
  const time = strTime.split(":");
  return Number(time[0]) * 60 + Number(time[1]);
};

export const getReadbleDuration = (duration: number): string => {
  const flooredDuration = Math.floor(duration);
  const { minutes, seconds } = intervalToDuration({
    start: 0,
    end: flooredDuration * 1000
  }) as { minutes: number; seconds: number };
  const minuteString =
    minutes && `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  const secondString =
    seconds && `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  return minuteString
    ? secondString
      ? `${minuteString}, ${secondString}`
      : minuteString
    : secondString
      ? secondString
      : "0 seconds";
};

export const getPageNameFromURL = (url: string): string => {
  const pageURLMap: Record<string, string> = {
    "/tanya/topic/": "Tanya Video Listing Page",
    "/tanya/chapter/": "Tanya Video Listing Page",
    "/tanya/section/": "Tanya Video Listing Page",
    "/tanya/": "Tanya Video Page",
    "/tanya": "Tanya Home Page",
    "/bimbel": "Live Classes Home Page",
    "/bimbel/user": "Live Classes User Home Page",
    "/": "Home Page"
  };

  const found = Object.keys(pageURLMap).find((pageURL) =>
    url.includes(pageURL)
  );
  return found ? pageURLMap[found] : "";
};

export const getLoadingPageName = (url: string): ILoadingPageName => {
  const pageURLMap: Record<string, LoaderPageName> = {
    "/tanya/topic/": LoaderPageName.tanyaCategory,
    "/tanya/chapter/": LoaderPageName.tanyaCategory,
    "/tanya/section/": LoaderPageName.tanyaCategory,
    "/tanya/questions/": LoaderPageName.tanyaCategory,
    "/tanya/": LoaderPageName.tanyaVideo
  };

  const found = Object.keys(pageURLMap).find((pageURL) =>
    url.includes(pageURL)
  );
  return found ? pageURLMap[found] : null;
};

export const getPageName = (): string | null => {
  const referer = document.referrer;
  return referer ? getPageNameFromURL(referer) : null;
};

export const getRefererSearchTerm = (referer: string): string | null => {
  const domainNames: string[] = ["google", "bing", "yahoo"];
  const isDomainName: string | undefined = domainNames.find(
    (name) => referer.includes(name) && queryString.parseUrl(referer)
  );
  const parsedQuery: queryString.ParsedUrl | null = isDomainName
    ? queryString.parseUrl(referer)
    : null;

  const searchQuery: string | null = parsedQuery
    ? parsedQuery.query["q"]?.toString() ||
      parsedQuery.query["p"]?.toString() ||
      null
    : null;

  return searchQuery;
};

export const isObjectEmpty = (
  obj: Record<never, unknown> | undefined
): boolean => {
  if (obj) {
    return Object.keys(obj).length === 0;
  }

  return false;
};

export const getDayMap: Record<string, string> = {
  Sen: "Senin",
  Sel: "Selasa",
  Rab: "Rabu",
  Kam: "Kamis",
  Jum: "Jumat",
  Sab: "Sabtu",
  Min: "Minggu"
};

export const calculateDiscountPercentage = (
  base_price: number,
  list_price: number
): string => {
  return Number(((base_price - list_price) / base_price) * 100).toFixed(2);
};

export const getGradeByClassType = (
  classType: LoginClassType
): IClassOptions[] =>
  classOptions.filter((option) => option.value.split("_")[2] === classType);

export const hasMultipleProfiles = (
  userData: AuthContextType["user"]["userData"]
): boolean => userData.student_users && userData.student_users.length > 1;

export const downloadFile = async (
  url: string,
  name: string
): Promise<undefined> => {
  if (!url) {
    throw new Error("Resource URL not provided! You need to provide one");
  }
  const blob = await wretch(url)
    .get()
    .res((response) => response.blob());

  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobURL;
  //@ts-expect-error
  a.style = "display: none";

  if (name && name.length) a.download = name;
  document.body.appendChild(a);
  a.click();

  return;
};

export const parseStringtoNull = (value: string): null | string =>
  value === "null" ? null : value;

export const getRobotsMetaContent = (): string =>
  process.env.NEXT_ENV !== "production" ? "none" : "index, follow";

// no-operation
export const noop = (): void => undefined;

// Klee’s Algorithm to calculate unique watch time from aray of watch time segments
export const lengthCovered = (arr: number[][]): number => {
  const points: [number, boolean][] = [];
  arr.forEach((el) => {
    points.push([el[0], false]);
    points.push([el[1], true]);
  });
  let resp = 0;
  let counter = 0;
  // eslint-disable-next-line no-nested-ternary
  points.sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0));
  points.forEach((el, index) => {
    if (counter) {
      resp += points[index][0] - points[index - 1][0];
    }
    counter += points[index][1] ? -1 : 1;
  });
  return resp;
};

export const getTotalWatchTime = (arr: number[][]): number => {
  const points: number[] = [];
  arr.forEach((el) => points.push(el[1] - el[0]));
  return points.reduce((a, b) => a + b, 0);
};

export const getSharedBy = (primaryUser: IPrimaryUser): string =>
  primaryUser ? primaryUser?.id : "anonymous";

export const getFeatureFlag = (key: string | undefined): boolean =>
  key && key === "true" ? true : false;

//@ts-ignore
//Using ts-ignore as location.reload doesn't have type signature with the current TS version
export const forcePageRefresh = (): void => window.location.reload(true);

export const getQueryParamsObjectFromString = (
  queryString: string
): Record<string, string> => {
  return queryString !== ""
    ? JSON.parse(
        '{"' +
          decodeURI(queryString.replace(/&/g, '","').replace(/=/g, '":"')) +
          '"}'
      )
    : "";
};

// util to conver 4SD to 5SD since 4SD doesn't have any package
export const handleGrade = (grade: string): string =>
  grade === "4" ? "5" : grade;

export const formatOptimizelyVariation = (variation: string | null) =>
  variation ? variation?.split("_").join(" ") : "null";

export const parseAPIErrorResponse = (
  err: unknown
): IApiError | { message: string } => {
  try {
    const error = JSON.parse((err as IApiError).message);
    return error as IApiError;
  } catch (e) {
    Sentry.captureException(err);
    return { message: constants.DEFAULT_UNEXPECTED_ERROR_MESSAGE };
  }
};

export const getSpendTime = () => {
  const spendData: IUserSpendTimeData = getLocalStorage(
    constants.USER_SPEND_TIME_KEY
  ) as IUserSpendTimeData;
  return spendData?.spendTime ?? 0;
};

export const getAppTooltipViewed = () => {
  const spendData: IUserSpendTimeData = getLocalStorage(
    constants.USER_SPEND_TIME_KEY
  ) as IUserSpendTimeData;
  return spendData?.viewed ?? false;
};

export const generateUUID = (): string => uuidV4() || "";

export const getPaginatedPath = (path: string, page: number): string => {
  return page === 1 ? path : `${path}/page/${page}`;
};

export const getMutualExclusiveExperimentToRender = (
  experiment1: {
    experimentName: OptimizelyExperiments;
    variation: string | null;
  },
  experiment2: {
    experimentName: OptimizelyExperiments;
    variation: string | null;
  }
) => {
  // if exp1 has a variation
  if (
    experiment1.variation === "variation_1" ||
    experiment1.variation === "variation_2" ||
    experiment1.variation === "variation_3"
  ) {
    return experiment1;
  }

  // if exp2 has a variant and exp1 is control
  if (
    experiment2.variation === "variation_1" &&
    experiment1.variation === "control"
  ) {
    return experiment2;
  }
  // if both are control, pick exp1 control
  if (
    experiment1.variation === "control" &&
    experiment2.variation === "control"
  ) {
    return experiment1;
  }
  // if exp1 is null, pick exp2
  if (experiment1.variation === null && experiment2.variation) {
    return experiment2;
  }
  // if exp2 is null, pick exp1
  if (experiment1.variation && experiment2.variation === null) {
    return experiment1;
  }
  if (experiment1.variation === null && experiment2.variation === null) {
    return null;
  }
};

export const urlHasPageNumber = (url: string, pageNumber: number): boolean =>
  url.endsWith(`/page/${pageNumber}`) ||
  url.includes(`/page/${pageNumber}/`) ||
  url.includes(`/page/${pageNumber}?`);

export const getExperimentAndVariationFromLocalStorage = (
  experimentName: OptimizelyExperiments
): { experimentName: OptimizelyExperiments; variation: string } | null => {
  const getExperimentFromLocalStorage = getLocalStorage(experimentName) as {
    variation: string;
  };

  if (!isObjectEmpty(getExperimentFromLocalStorage)) {
    const { variation } = getExperimentFromLocalStorage;
    return {
      experimentName: experimentName,
      variation: variation
    };
  }
  return null;
};

export const didUserRegisterViaBlocker = (): string | null => {
  const anonUserRegisterViaBlocker = getLocalStorage(
    constants.ANON_USER_REGISTER_VIA_BLOCKER
  ) as { experimentName: string };
  if (
    anonUserRegisterViaBlocker &&
    !isObjectEmpty(anonUserRegisterViaBlocker)
  ) {
    return anonUserRegisterViaBlocker.experimentName;
  } else {
    return null;
  }
};

export const addScriptToDocument = (
  src: string,
  callbacks: { onLoad: () => void; onError: () => void }
) => {
  const s = document.createElement("script");
  s.setAttribute("src", src);
  s.type = "text/javascript";
  s.onload = callbacks.onLoad;
  s.onerror = callbacks.onError;
  document.body.appendChild(s);
  return null;
};

export const getRandomItem = <T>(list: T[]): T => {
  return list[Math.floor(Math.random() * list.length)];
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};

export const asyncPoll = async <T>(
  asyncFn: () => Promise<T>,
  fnCondition: (responseData: T) => boolean,
  {
    retryDelay = 2000,
    maxRetries = 5
  }: { retryDelay: number; maxRetries: number }
): Promise<T> => {
  let noOfTries = 1;
  let result = await asyncFn();
  while (!fnCondition(result) && noOfTries <= maxRetries) {
    await delay(retryDelay);
    result = await asyncFn();
    noOfTries += 1;
  }

  return result;
};

export const numDaysBetweenDates = (d1: Date, d2: Date): number => {
  const diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

export const isClient = (): boolean => {
  return typeof window !== "undefined";
};

export const getIndonesianTimezone = (): string => {
  const timezoneValue: { [key: string]: string } = {
    "GMT+07:00": "WIB",
    "GMT+08:00": "WITA",
    "GMT+09:00": "WIT"
  };

  return timezoneValue[format(new Date(), "OOOO")] || "";
};

export const buildQueryParams = (
  params: Record<string, string | undefined | null>
): string => {
  const validQueryParams = Object.entries(params).reduce(
    (acc: Record<string, string>, [key, value]) => {
      let parsedValue = value;
      if (Array.isArray(parsedValue)) {
        parsedValue = parsedValue.join(",");
      }
      if (
        parsedValue !== "" &&
        parsedValue !== undefined &&
        parsedValue !== null
      ) {
        acc[key] = parsedValue;
      }
      return acc;
    },
    {}
  );

  return new URLSearchParams(validQueryParams).toString();
};

export const convertToJKTFormat = (
  timeStamp: Date,
  options?: Intl.DateTimeFormatOptions
): string =>
  new Intl.DateTimeFormat("en-US", {
    timeZone: "Asia/Jakarta",
    ...options
  }).format(timeStamp);

export async function permissionsCheck() {
  const read = await navigator.permissions.query({
    name: "clipboard-read" as PermissionName
  });
  const write = await navigator.permissions.query({
    name: "clipboard-write" as PermissionName
  });
  return write.state === "granted" && read.state !== "denied";
}

export const copyToClipboard = async (text: string): Promise<void> => {
  try {
    const hasPermissions = await permissionsCheck();

    if (hasPermissions && document.hasFocus()) {
      return navigator.clipboard?.writeText(text);
    } else {
      alert("Please turn on permission to copy");
    }
  } catch (err) {
    console.error(err);
  }
};

export const getRelativeDateToTodayDateOnly = (day: number): Date => {
  const todayDate = new Date();
  const newDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate() + day
  );
  return newDate;
};
