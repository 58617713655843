import { breakpoints } from "styles";
import styled from "styled-components";

export const LogoWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 20px;
  cursor: pointer;

  @media ${breakpoints.tablet} {
    width: 120px;
    height: 30px;
  }
`;
