import { useState } from "react";
import {
  ProfileContainer,
  ProfileDetailsItem,
  ProfileDetailsContainer,
  ProfileSelectionContainer,
  SelectProfileButtonContainer,
  SelectProfileButton,
  ProfileDesktopAvatar,
  ProfileMobileAvatar
} from "./profile-selection.styles";
import userProfileImage from "public/assets/home/default-user-profile.png";
import { IProfileSelectionProps } from "./profile-selection.types";
import Image from "next/legacy/image";
import { switchPrimaryUser } from "services/api/users/user-services";
import { useToast } from "components/atoms/toast/toast-context";
import trackEvent, {
  identifyUser,
  registerSuperProperties,
  setUserProperties
} from "services/mixpanel/mixpanel";
import { eventNames } from "services/mixpanel/events";
import { useAuth } from "stores/auth/auth-context";
import { useCookies } from "react-cookie";
import { constants } from "../../../constants/constants";
import {
  IAuthCookie,
  IAuthCookieParent,
  IAuthCookieStudent
} from "../../../services/api/auth/authService.types";
import { useKelasLiveContext } from "stores/kelas-live/kelas-live-context";
import { useRedirectAfterLogin } from "hooks/use-redirect-after-login/use-redirect-after-login";

const ProfileSelection: React.FC<IProfileSelectionProps> = ({
  userData,
  setUserData,
  isLoginPage = false,
  onClose
}) => {
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(
    null
  );
  const [isSwitchingProfile, setIsSwitchingProfile] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie, removeCookie] = useCookies();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { userSubscription } = useKelasLiveContext();
  const { redirectAfterLogin } = useRedirectAfterLogin();

  const updatePrimaryUser = async () => {
    try {
      if (selectedProfileID !== null) {
        setIsSwitchingProfile(true);
        const { access_token, refresh_token, expires_in, token_type } =
          await switchPrimaryUser(
            selectedProfileID,
            userData.primary_user.access_token
          );

        const studentDetails = userData.student_users.find(
          (student) => student.id === selectedProfileID
        );

        const updatedPrimaryUser = {
          ...userData.primary_user,
          ...studentDetails,
          access_token,
          refresh_token,
          expires_in,
          token_type
        };
        setUserData({ ...userData, primary_user: updatedPrimaryUser });
        identifyUser(updatedPrimaryUser.id);
        registerSuperProperties({
          userRole: userData.userType,
          name: updatedPrimaryUser.full_name,
          class: updatedPrimaryUser.class_type,
          email: updatedPrimaryUser.email,
          grade: updatedPrimaryUser.grade,
          id: updatedPrimaryUser.id,
          studentId: updatedPrimaryUser.student_id,
          stream: updatedPrimaryUser.stream
        });
        setUserProperties({
          name: updatedPrimaryUser.full_name,
          email: updatedPrimaryUser.email,
          id: updatedPrimaryUser.id,
          mobile: updatedPrimaryUser.phone_number,
          whatsAppConsent: user.isWhatsappConsentGiven,
          onBoardingDate: null,
          isOnboarded: true,
          grade: updatedPrimaryUser.grade,
          stream: updatedPrimaryUser.stream,
          class: updatedPrimaryUser.class_type
        });
        trackEvent({ eventName: eventNames.studentProfileSwitched });

        const primaryAuthData: IAuthCookie = {
          primary_user: updatedPrimaryUser
        };
        removeCookie(constants.userContext.cookieKey, { path: "/" });
        setCookie(
          constants.userContext.cookieKey,
          JSON.stringify(primaryAuthData),
          { path: "/" }
        );

        const studentData: IAuthCookieStudent = {
          student_users: userData.student_users
        };
        removeCookie(constants.userContext.cookieKeyStudent, { path: "/" });
        setCookie(constants.userContext.cookieKeyStudent, studentData, {
          path: "/"
        });

        const parentData: IAuthCookieParent = {
          parent_user: userData.parent_user
        };
        removeCookie(constants.userContext.cookieKeyParent, { path: "/" });
        setCookie(constants.userContext.cookieKeyParent, parentData, {
          path: "/"
        });

        // Fetch subscription status of the new primary user
        await userSubscription.fetchSubscriptionStatus(
          updatedPrimaryUser.access_token
        );
      }

      onClose && onClose();
      if (isLoginPage) {
        redirectAfterLogin();
      }
      setIsSwitchingProfile(false);
    } catch (error) {
      addToast({
        heading: "Maaf ya, ada yang salah. Coba lagi, yuk.",
        variant: "error"
      });
    }
  };

  return (
    <>
      <ProfileSelectionContainer isUIBlocked={isSwitchingProfile}>
        {userData.student_users.map((student, index) => (
          <ProfileContainer
            data-testid={`student-profile-${index}`}
            isSelected={selectedProfileID === student.id}
            onClick={() => setSelectedProfileID(student.id)}
            key={student.id}
          >
            <ProfileDesktopAvatar>
              <Image
                src={userProfileImage}
                alt={student.full_name}
                objectFit="cover"
                layout="fixed"
                height="72"
                width="72"
              />
            </ProfileDesktopAvatar>
            <ProfileMobileAvatar>
              <Image
                src={userProfileImage}
                alt={student.full_name}
                objectFit="cover"
                layout="fixed"
                height="56"
                width="56"
              />
            </ProfileMobileAvatar>
            <ProfileDetailsContainer>
              <ProfileDetailsItem>{student.full_name}</ProfileDetailsItem>
              <ProfileDetailsItem>
                {student.class_type} {student.grade} {student.stream}
              </ProfileDetailsItem>
            </ProfileDetailsContainer>
          </ProfileContainer>
        ))}
      </ProfileSelectionContainer>
      <SelectProfileButtonContainer>
        <SelectProfileButton
          data-testid="select-student-profile"
          onClick={updatePrimaryUser}
          variant="primary"
          disabled={selectedProfileID === null || isSwitchingProfile}
          isLoading={isSwitchingProfile}
          loadingText="Pilih profil"
        >
          Pilih profil
        </SelectProfileButton>
      </SelectProfileButtonContainer>
    </>
  );
};

export { ProfileSelection };
