import Image from "next/legacy/image";
import React from "react";

import { Logo } from "../logo/logo";
import {
  AddressContainer,
  CocoImage,
  ColearnContainer,
  ContactUsContainer,
  ContactUsMenuItem,
  DownloadContainer,
  FeaturesNav,
  FooterAddressText,
  FooterContainer,
  FooterCopyright,
  FooterDetailsContainer,
  FooterExtraLink,
  FooterExtraLinkItem,
  FooterExtraLinksContainer,
  FooterHeading,
  FooterMenu,
  FooterMenuItem,
  FooterMenuLink,
  FooterMenuLinkHighlight,
  FooterMessage,
  FooterSection,
  FooterSocialContainer,
  FooterSocialIcon,
  FooterSocialIconItem,
  FooterSocialWrapper,
  NecoImage,
  StyledFooter,
  SubFooter,
  SubFooterContainer
} from "./footer.styles";

import CocoTeaching from "public/assets/coco_teaching.svg";
import FacebookIcon from "public/assets/facebook.svg";
import InstagramIcon from "public/assets/instagram.svg";
import LinkedInIcon from "public/assets/linkedin.svg";
import NecoBathing from "public/assets/neco_bathing.svg";
import TikTokIcon from "public/assets/tiktok.svg";
import YoutubeIcon from "public/assets/youtube.svg";

import { DownloadAppCTAs } from "components/download-app-ctas/download-app-ctas";
import { IComponentWithSource } from "types/common/common.types";
import { isMarketingPage, shouldHideMainFooterSection } from "utils/utils";
import { useRouter } from "next/router";

const Footer: React.FC<IComponentWithSource> = ({ eventSource }) => {
  const router = useRouter();

  const hideMainFooterSection =
    isMarketingPage(router.pathname) ||
    shouldHideMainFooterSection(router.pathname);

  return (
    <>
      {!hideMainFooterSection && (
        <StyledFooter>
          <FooterContainer>
            <FooterSection>
              <Logo />
              <FooterMessage>
                Mempersiapkan generasi Indonesia menghadapi tantangan global.
              </FooterMessage>
              <DownloadContainer>
                <FooterHeading>Download CoLearn sekarang!</FooterHeading>
                <DownloadAppCTAs eventSource={eventSource} />
              </DownloadContainer>
            </FooterSection>
            <FooterDetailsContainer>
              <FeaturesNav>
                <FooterHeading>Features</FooterHeading>
                <FooterMenu>
                  <FooterMenuItem>
                    <FooterMenuLink href="/bimbel">Bimbel</FooterMenuLink>
                  </FooterMenuItem>
                  <FooterMenuItem>
                    <FooterMenuLink href="/paket">Paket Belajar</FooterMenuLink>
                  </FooterMenuItem>
                  <FooterMenuItem>
                    <FooterMenuLink href="/tanya">Tanya</FooterMenuLink>
                  </FooterMenuItem>
                </FooterMenu>
              </FeaturesNav>

              <CocoImage>
                <Image
                  src={CocoTeaching}
                  alt="Coco Teaching"
                  layout="fill"
                  objectFit="fill"
                />
              </CocoImage>

              <AddressContainer>
                <FooterHeading>Alamat</FooterHeading>
                <FooterMenu>
                  <FooterAddressText>CoLearn Headquarter</FooterAddressText>
                  <FooterAddressText>
                    GoWork Millennium Centennial Center Lt. 42
                  </FooterAddressText>
                  <FooterAddressText>
                    Jl. Jenderal Sudirman Kav. 25
                  </FooterAddressText>
                  <FooterAddressText>Jakarta Selatan 12920</FooterAddressText>
                </FooterMenu>
              </AddressContainer>
              <ColearnContainer>
                <FooterHeading>CoLearn</FooterHeading>
                <FooterMenu>
                  <FooterMenuItem></FooterMenuItem>
                  <FooterMenuItem>
                    <FooterMenuLink
                      href="https://colearn.bamboohr.com/careers"
                      rel="nofollow"
                    >
                      Karir
                    </FooterMenuLink>
                  </FooterMenuItem>
                </FooterMenu>
              </ColearnContainer>
              <ContactUsContainer>
                <FooterHeading>Hubungi kami</FooterHeading>
                <FooterMenu>
                  <ContactUsMenuItem>
                    <FooterMenuLink href="https://api.whatsapp.com/send?phone=628174759759&text=Halo%20CoLearn">
                      Konselor Akademik
                    </FooterMenuLink>
                  </ContactUsMenuItem>

                  <ContactUsMenuItem>
                    <FooterMenuLinkHighlight href="mailto:info@colearn.id">
                      info@colearn.id
                    </FooterMenuLinkHighlight>
                  </ContactUsMenuItem>
                  <ContactUsMenuItem>
                    <FooterMenuLinkHighlight href="tel:02150108008">
                      021 - 5010 8008
                    </FooterMenuLinkHighlight>
                  </ContactUsMenuItem>
                  <ContactUsMenuItem>
                    <FooterMenuLinkHighlight href="tel:081990898008">
                      0819 9089 8008
                    </FooterMenuLinkHighlight>
                  </ContactUsMenuItem>
                </FooterMenu>
              </ContactUsContainer>
            </FooterDetailsContainer>
          </FooterContainer>
        </StyledFooter>
      )}
      <SubFooter>
        <SubFooterContainer>
          <FooterCopyright>
            Copyright © PT IQ EDUKASI. Hak Cipta Dilindungi.
          </FooterCopyright>
          <FooterExtraLinksContainer>
            <FooterExtraLinkItem>
              <FooterExtraLink href="/kebijakan-privasi">
                Kebijakan Privasi
              </FooterExtraLink>
            </FooterExtraLinkItem>
            <FooterExtraLinkItem>
              <FooterExtraLink href="/ketentuan-layanan">
                Ketentuan Layanan
              </FooterExtraLink>
            </FooterExtraLinkItem>
            <FooterExtraLinkItem>
              <FooterExtraLink href="/garansi-uang-kembali">
                Kebijakan Pengembalian Dana
              </FooterExtraLink>
            </FooterExtraLinkItem>
          </FooterExtraLinksContainer>
          <FooterSocialWrapper>
            <FooterSocialContainer>
              <FooterSocialIconItem>
                <FooterSocialIcon
                  href="https://www.facebook.com/colearn.id"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  title="CoLearn Facebook"
                >
                  <Image
                    src={FacebookIcon}
                    alt="Visit Colearn on Facebook"
                    objectFit="contain"
                    layout="fill"
                  />
                </FooterSocialIcon>
              </FooterSocialIconItem>
              <FooterSocialIconItem>
                <FooterSocialIcon
                  href="https://www.instagram.com/colearn.id/"
                  title="CoLearn Instagram"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <Image
                    src={InstagramIcon}
                    alt="Visit Colearn on Instagram"
                    objectFit="contain"
                    layout="fill"
                  />
                </FooterSocialIcon>
              </FooterSocialIconItem>
              <FooterSocialIconItem>
                <FooterSocialIcon
                  href="https://www.tiktok.com/@colearn.id"
                  title="CoLearn TikTok"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <Image
                    src={TikTokIcon}
                    alt="Visit Colearn on Tiktok"
                    objectFit="contain"
                    layout="fill"
                  />
                </FooterSocialIcon>
              </FooterSocialIconItem>
              <FooterSocialIconItem>
                <FooterSocialIcon
                  href="https://www.youtube.com/c/colearn"
                  title="CoLearn Youtube"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <Image
                    src={YoutubeIcon}
                    alt="Visit Colearn on Youtube"
                    objectFit="contain"
                    layout="fill"
                  />
                </FooterSocialIcon>
              </FooterSocialIconItem>
              <FooterSocialIconItem>
                <FooterSocialIcon
                  href="https://www.linkedin.com/company/colearnid/"
                  title="CoLearn LinkedIn"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <Image
                    src={LinkedInIcon}
                    alt="Visit Colearn on LinkedIn"
                    objectFit="contain"
                    layout="fill"
                  />
                </FooterSocialIcon>
              </FooterSocialIconItem>
            </FooterSocialContainer>
          </FooterSocialWrapper>
          {!hideMainFooterSection && (
            <NecoImage>
              <Image
                src={NecoBathing}
                alt="Neco Bathing"
                layout="fill"
                objectFit="fill"
              />
            </NecoImage>
          )}
        </SubFooterContainer>
      </SubFooter>
    </>
  );
};

export { Footer };
