import styled from "styled-components";
import { Container } from "styles/common/container";
import { breakpoints, zIndex, sizes } from "styles";

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${sizes.headerHeight};
  display: flex;
  align-items: center;
  padding: 8px 0;
  z-index: ${zIndex.header};

  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export const HamburgerIcon = styled.div`
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin-right: 16px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  @media ${breakpoints.laptop} {
    padding: 0;
  }
`;

export const HeaderCTA = styled.button<{ $placeRight?: boolean }>`
  background-color: ${({ theme }) => theme.colors.brand["500"]};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  ${({ $placeRight }) => ($placeRight ? "margin-left: auto;" : "")}
`;

export const HeaderMenuCTA = styled.button`
  background-color: ${({ theme }) => theme.colors.brand["50"]};
  color: ${({ theme }) => theme.colors.brand["500"]};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  margin-left: initial;
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
`;

export const DownloadButton = styled.button<{ $isFullWidth?: boolean }>`
  padding: 10px 16px;
  background: ${({ theme }) => theme.colors.brand["500"]};
  border-radius: 6px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  margin: 4px 0 0 auto;
  cursor: pointer;
  width: ${({ $isFullWidth }) => $isFullWidth && "100%"};
  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const MobileMenuContainer = styled.nav<{ $active: boolean }>`
  position: absolute;
  top: ${sizes.headerHeight};
  left: 0;
  right: 0;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  transform: translateX(${(props) => (props.$active ? "0" : "-100%")});
  transition: transform 0.3s ease;
  min-height: 100vh;
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.laptop} {
    display: none;
  }
`;

export const MenuContainer = styled.nav`
  display: none;

  @media ${breakpoints.laptop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media ${breakpoints.laptop} {
    display: flex;
    align-items: center;
    margin: 0 32px 0 32px;
  }
`;

export const MenuItem = styled.li`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.black};

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brand[500]};
  }

  @media ${breakpoints.laptop} {
    margin-right: 24px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const MenuLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  padding: 14px 0;
  transition: all 0.3s ease;
  position: relative;
  @media ${breakpoints.laptop} {
    white-space: nowrap;
  }
`;

export const MenuDivider = styled.hr`
  width: 100%;
  background: ${({ theme }) => theme.colors.brand[100]};
  height: 2px;
  border: none;
  border-radius: 4px;
`;

export const MobileUserProfileContainer = styled.div`
  margin-bottom: 16px;
`;
