import styled from "styled-components";
import { breakpoints, zIndex } from "styles";
import { ModalSize } from "./modal.types";

const ModalWidths = {
  normal: "540px",
  wide: "900px",
  narrow: "480px",
  tiny: "360px",
  extraWide: "1050px",
  full: "100vw"
};

export const ModalStyles = styled.div<{
  size: ModalSize;
  backgroundColor?: string;
}>`
  position: fixed;
  transform: translate(-50%, 0%);
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.white};
  z-index: ${zIndex.modal};
  border-radius: 10px 10px 0px 0px;
  width: 90%;
  bottom: 0px;
  left: 50%;
  right: 50%;
  overflow-y: scroll;

  ${({ size }) =>
    size === ModalSize.Full
      ? `
          height: 100%;
          width: 100vw;
          box-sizing: border-box;
        `
      : `
        max-height: calc(100vh - 98px);
        padding: 20px;
      `}

  @media ${breakpoints.laptop} {
    border-radius: 10px;
    width: ${({ size }) => ModalWidths[size]};
    padding: 32px;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 50%;
    bottom: unset;
    transform: translate(-50%, -50%);

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const OverlayStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.transparent["black70"]};
  z-index: ${zIndex.modal};
`;

export const ModalHeading = styled.p`
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 36px;
  margin: 0;
`;

export const ModalHeader = styled.div<{ heading?: boolean }>`
  display: flex;
  justify-content: ${({ heading }) => (heading ? "space-between" : "flex-end")};
`;
